import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../custom-loader/CustomLoader";
import { PreviousInsuranceApi } from "../../api/services/PrevInsuranceService";
import { UserManager } from "oidc-client";
import oidcConfig from "../../config/oidc-config";
import Modal from "react-bootstrap/Modal";
const userManager = new UserManager(oidcConfig);

async function getAccessToken() {
  try {
    const user = await userManager.getUser();
    if (!user) {
      throw new Error("User is not authenticated");
    }
    return user.access_token;
  } catch (error) {
    throw error;
  }
}

const PayerCallBack = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  localStorage.setItem("Payer_Code", code);
  const error = urlParams.get("error");
  const [show, setShow] = useState(false);
  const [isError, setIsError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  
  const handleClose = () => {
    navigate("/PreviousInsurer");
  };

  const selectedPayer = sessionStorage.getItem("selected_payer");
  const fetchPayerToken=async()=>{
    let accessToken;
    let userSub = "";
    accessToken = await getAccessToken();
    const tokenPayload = JSON.parse(atob(accessToken.split(".")[1]));
    userSub = tokenPayload.sub;
    const data={
      ssoUserID:userSub,
      payerID:selectedPayer,
      code:code

    }
try{
  const response = await PreviousInsuranceApi.fetchPayerTokenDetails(data)
  navigate("/PreviousInsurer");
  return response
}catch(fetchError){
  setIsError(fetchError?.error_description);
  setIsLoading(false);
}


  }

  useEffect(() => {
    if (code) {
      fetchPayerToken();
    }
  }, [code]);

  useEffect(() => {
    if (error) {
      setShow(true);
      setIsLoading(false);
    }
  }, [error]);


  return (
    <>
    {isLoading && <CustomLoader />}
      <Modal
        show={show}
        onHide={handleClose}
        centered
        className="custom-pop-up about-me-popup"
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Redirection</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isError === ""
            ? isError
            : "Request denied by the user. Please close the popup to move to the home page."}
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-border-primary me-2"
            onClick={() => {
              handleClose();
            }}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PayerCallBack;